<template>
  <section
    class="home-solutions-row full-width"
    v-if="checkData || checkSolutionIsActive"
  >
    <div class="divider-design" />
    <div class="container">
      <div class="divider-design-one">
        <label>
          <picture class="image w-full block">
            <source
              media="(min-width: 767px)"
              :srcset="getMainDataIconDesktop"
            />
            <img :src="getMainDataIconMobile" alt="logo" />
          </picture>
        </label>
      </div>
      <div class="home-solutions-top-row flex">
        <div class="home-solutions-section-left">
          <h2>
            {{ $t(getMainDataTextsTitle) }}
          </h2>
          <p>
            {{ getMainDataTextsHashtag }}
          </p>
        </div>
        <div class="home-login-section-right relative">
          <p>
            {{ $t(getMainDataTextsDescription) }}
          </p>
          <div>
            <nuxt-link class="arrow-link" :to="localePath(getMainDataTextsUrl)">
              {{ $t(getMainDataTextsLinkLabel) }}
              <ArrowIcon />
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="home-solutions-bottom-row flex">
        <div class="home-solutions-section-left">
          <picture class="image w-full block">
            <source
              media="(min-width: 767px)"
              :srcset="getMainDataImageDesktop"
            />
            <img
              :src="getMainDataImageMobile"
              alt="solution image"
              @error="handleImageError"
              width="300"
              :class="handleImageError ? 'placeholder' : ''"
              height="200"
              class="w-full h-auto"
            />
          </picture>
        </div>
        <div class="home-login-section-right relative flex">
          <div
            class="solutions-col"
            v-for="(item, index) in getSolutionDetails"
            :key="index"
          >
            <nuxt-link :to="localePath(item.link.url)">
              <picture class="image w-full block">
                <source
                  media="(min-width: 767px)"
                  :srcset="
                    item.icon && item.icon.desktop ? item.icon.desktop : ''
                  "
                />
                <img
                  :src="item.icon && item.icon.mobile ? item.icon.mobile : ''"
                  :alt="item.alt ? item.alt : 'solution icon'"
                />
              </picture>
              <p>
                <span class="block">{{ item.number ? item.number : '' }}</span>
                {{ $t(item.text ? item.text : '') }}
              </p>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { ArrowIcon } from '~/components/General/Icons';

export default defineComponent({
  name: 'HomeSolutions',
  components: {
    ArrowIcon,
  },
  setup() {
    const { locale } = useI18n();
    const { blocks, loading, loadBlocks } = useContent(
      `solutions-block${locale.includes('it') ? 'it' : 'en'}`
    );
    const solutionBlockSelector = `solutions-block-${
      locale.includes('it') ? 'it' : 'en'
    }`;
    const solutionBlockContent = ref();
    const checkSolutionIsActive = ref();
    const getMainDataIconDesktop = ref();
    const getMainDataIconMobile = ref();
    const getMainDataImageDesktop = ref();
    const getMainDataImageMobile = ref();
    const getMainDataTextsTitle = ref();
    const getMainDataTextsHashtag = ref();
    const getMainDataTextsDescription = ref();
    const getMainDataTextsUrl = ref();
    const getMainDataTextsLinkLabel = ref();
    const getSolutionDetails = ref();
    const checkData = ref();
    useFetch(async () => {
      await loadBlocks({ identifiers: [solutionBlockSelector] });
      try {
        const blockData = contentGetters.getCmsBlockContent(blocks.value);
        solutionBlockContent.value = blockData[solutionBlockSelector];
        checkData.value = solutionBlockContent.value?.data;
        checkSolutionIsActive.value =
          solutionBlockContent.value?.active || false;
        getMainDataIconDesktop.value =
          solutionBlockContent.value?.data?.main?.icon?.desktop || '';
        getMainDataIconMobile.value =
          solutionBlockContent.value?.data?.main?.icon?.mobile || '';
        getMainDataTextsTitle.value =
          solutionBlockContent.value?.data?.main?.texts?.title || '';
        getMainDataTextsHashtag.value =
          solutionBlockContent.value?.data?.main?.texts?.hashtag ||
          '#wearecolfert';
        getMainDataTextsDescription.value =
          solutionBlockContent.value?.data?.main?.texts?.description || '';
        getMainDataTextsUrl.value =
          solutionBlockContent.value?.data?.main?.texts?.cta?.url || '/';
        getMainDataTextsLinkLabel.value =
          solutionBlockContent.value?.data?.main?.texts?.cta?.label ||
          'Find out more';
        getMainDataImageDesktop.value =
          solutionBlockContent.value?.data?.main?.image?.desktop ||
          '/homepage/home-solutions.jpg';
        getMainDataImageMobile.value =
          solutionBlockContent.value?.data?.main?.image?.mobile ||
          '/homepage/home-solutions.jpg';
        getSolutionDetails.value =
          solutionBlockContent.value?.data?.details || [];
      } catch {
        console.warn('Unable to fetch block content.');
      }
    });
    return {
      solutionBlockContent,
      loading,
      checkSolutionIsActive,
      getMainDataIconDesktop,
      getMainDataIconMobile,
      getMainDataTextsTitle,
      getMainDataTextsHashtag,
      getMainDataTextsDescription,
      getMainDataTextsUrl,
      getMainDataTextsLinkLabel,
      getMainDataImageDesktop,
      getMainDataImageMobile,
      getSolutionDetails,
      checkData,
    };
  },
  methods: {
    handleImageError(event) {
      event.target.src = '/icons/logo.svg';
    },
  },
});
</script>

<style lang="scss">
.home-solutions-row {
  width: 100%;
  position: relative;
  padding-bottom: 1.75rem;
  .home-solutions-top-row {
    padding-bottom: 5rem;
    @include to-tablet-max {
      display: var(--block);
      padding-bottom: 1.875rem;
    }
    .home-solutions-section-left {
      width: 58%;
      padding-right: 3.125rem;
      @include to-tablet-max {
        width: 100%;
        padding-right: 0;
        padding-bottom: 2rem;
      }
      h2 {
        font-family: var(--lato-medium);
        font-size: var(--font-size-64);
        line-height: var(--line-height-68);
        margin-bottom: 1.125rem;
        font-weight: normal;
        margin-bottom: 2rem;
        @include to-tablet-1500 {
          font-size: var(--font-size-28);
          line-height: var(--line-height-36);
          margin-bottom: 1rem;
          max-width: 25rem;
        }
        @include to-mobile-max {
          font-family: var(--lato-regular);
        }
      }
      p {
        font-size: var(--font-size-36);
        line-height: var(--line-height-36);
        font-family: var(--lato-semibold);
        color: var(--c-yellow);
        font-weight: normal;
        @include to-tablet-1500 {
          font-size: var(--font-size-24);
          line-height: var(--line-height-34);
          font-family: var(--lato-medium);
        }
      }
      a {
        @include to-mobile-max {
          font-family: var(--lato-semibold);
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
        }
      }
      button {
        min-width: 12.938rem;
        font-size: var(--font-size-12);
        line-height: var(--line-height-12);
        padding: 0.688rem 1.25rem;
        text-transform: inherit;
        @include to-mobile-max {
          width: 100%;
          min-width: 100%;
        }
      }
      .registered-user {
        margin: 1.875rem 0 0.5rem 0;
        color: var(--c-yellow);
      }
    }
    .home-login-section-right {
      width: 42%;
      @include to-tablet-max {
        width: 100%;
      }
      p {
        font-size: var(--font-size-20);
        line-height: var(--line-height-26);
        margin-bottom: 1.688rem;
        @include to-mobile-max {
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
        }
      }
    }
  }
  .home-solutions-bottom-row {
    padding-bottom: 5rem;
    align-items: var(--align-items);
    @include to-tablet-max {
      display: var(--block);
      padding-bottom: 1.125rem;
    }
    .home-solutions-section-left {
      width: 37%;
      position: var(--relative);
      @include to-tablet-max {
        width: 100%;
        padding-top: 1rem;
        &::after {
          content: '';
          width: 100%;
          background: var(--c-yellow);
          height: 0.063rem;
          top: var(--top-0);
          position: var(--absolute);
          left: var(--left-0);
        }
      }
    }
    .home-login-section-right {
      width: 63%;
      padding-left: 3.75rem;
      @include to-tablet-max {
        flex-wrap: var(--flex-wrap);
      }
      @include to-tablet-1500 {
        padding-left: 1.875rem;
      }
      @include to-tablet-max {
        width: 100%;
        padding: 1.563rem 0 0 0;
      }
      .solutions-col {
        text-align: center;
        width: 20%;
        padding: 0 0.313rem;
        a {
          color: var(--c-dark-grey);
        }
        @include to-mobile-max {
          width: 50%;
          padding: 1.063rem 0.313rem;
        }
        p {
          font-size: var(--font-size-20);
          line-height: var(--line-height-26);
          @include to-tablet-1500 {
            font-size: var(--font-size-16);
            line-height: var(--line-height-16);
          }
          span {
            font-size: 32px;
            line-height: var(--line-height-32);
            font-family: var(--lato-medium);
            margin-bottom: 0.625rem;
            @include to-tablet-1500 {
              font-size: var(--font-size-24);
              line-height: var(--line-height-28);
            }
          }
          @include to-mobile-max {
            font-size: var(--font-size-13);
            line-height: var(--line-height-16);
          }
        }
        svg,
        img {
          margin-bottom: 2.5rem;
          max-height: 6rem;
          @include to-tablet-1500 {
            max-width: 3.75rem;
            height: var(--h-auto);
          }
          @include to-mobile-max {
            margin-bottom: 0.75rem;
          }
        }
      }
    }
  }
}
.divider-design-one {
  display: var(--flex);
  align-items: var(--align-items);
  margin: 2.188rem 0 0.75rem 0;
  @include to-mobile-max {
    margin: 1rem 0 1rem 0;
  }
  label {
    min-width: 20.188rem;
    content: '';
    img {
      max-width: 5rem;
    }
    @include for-desktop {
      min-width: 19.063rem;
    }
    @include to-mobile-max {
      min-width: 5.625rem;
      width: 30%;
      max-width: 30%;
      svg,
      img {
        width: 3.5rem;
        height: auto;
      }
    }
  }
  &::after {
    content: '';
    width: 100%;
    background: var(--c-yellow);
    height: 0.063rem;
  }
}
</style>
